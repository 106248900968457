import { Button, Typography } from '@mui/material';
import { Link } from '@tanstack/react-router';

import { useTranslate } from 'hooks/useTranslate';

import c from './_defaultError.module.scss';

export const DefaultError = () => {
  const { t } = useTranslate('defaultError');

  return (
    <div className={c.wrapper}>
      <Typography>{t('errorLoadingPage')}</Typography>
      <Link to="/home/">
        <Button>
          {t('clickHereToReturn', {
            location: t('nav.home', { ns: 'general' }),
          })}
        </Button>
      </Link>
    </div>
  );
};
