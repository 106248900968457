import { QueryClient } from '@tanstack/react-query';

import { preloadRegisteredApps, useQueryRegisteredApps } from './useQuery/useQueryRegisteredApps';

export const useIsRegistered = (id?: string): boolean => {
  return (useQueryRegisteredApps(id).data || [])?.[0] !== undefined;
};

export const getisRegistered = async (queryClient: QueryClient, id?: string) => {
  return (await preloadRegisteredApps(queryClient, id))?.[0] !== undefined;
};
