import { useTranslation } from 'react-i18next';

import { TranslationKeys } from 'locales/en';

type Ks = keyof TranslationKeys;

// TODO: Weird caveat, this hook does not automatically infer namespaces unless there are at least three namespaces
// Shouldn't ever be a problem in practice, but worth noting

export const useTranslate = <N extends Ks>(...args: Parameters<typeof useTranslation<N>>) => {
  const { t, ...rest } = useTranslation(...args);

  type Subset = Exclude<keyof TranslationKeys, N>;

  const translate = <K extends N, N2 extends Subset = Subset>(
    key: keyof TranslationKeys[N2] extends never ? keyof TranslationKeys[K] : keyof TranslationKeys[N2],
    options?: {
      ns?: N2;
      [key: string]: unknown;
    },
  ) => {
    return t(key as string, options) as string;
  };

  return {
    t: translate,
    ...rest,
  };
};
