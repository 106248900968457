import { createRouter } from '@tanstack/react-router';

import { queryClient } from './queryClient';
import { routeTree } from './routeTree.gen';

export const router = createRouter({
  routeTree,
  context: {
    back: undefined,
    background: {
      gradient: true,
      src: 'https://front.travpromobile.com/760/bg.jpg',
    },
    breadcrumbs: true,
    disableButtonBar: false,
    disableSupportButton: false,
    queryClient,
    hideButtonBarButtons: [],
  },
  defaultPreload: false,
  defaultPreloadDelay: 250,
  defaultPreloadStaleTime: 0,
  trailingSlash: 'always',
});

export type Router = typeof router;
