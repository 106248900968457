import { fetch } from 'api/typedFetch';

import { localStorage } from 'helpers/localStorage';

export const setData = async () => {
  const user = localStorage.get('USER');

  if (user?.email === undefined) {
    throw new Error('User not logged in');
  }

  const response = await fetch('REGISTER_USER_PROGRESS', {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      user: {
        email: user.email,
      },
    }),
  });

  if (response.ok === true) {
    return response;
  }

  const data = await response.json();
  const errors = data?.errors?.email;

  console.error('Failed to register user progress:', errors);
  return response;
};
