import { Suspense } from 'react';

import { QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from '@tanstack/react-router';
import ReactDOM from 'react-dom/client';
import { type NonCircularLinkProps } from 'typescript/types/NonCircularLinkProps';

import { StrictMode } from 'components/StrictMode';

import { loadSentry } from 'helpers/sentry';

import { queryClient } from './queryClient';
import { router } from './router';

// Load Sentry
loadSentry(router);

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

declare module '@tanstack/react-router' {
  interface StaticDataRouteOption {
    [key: string]: string | string[] | boolean | null | undefined | NonCircularLinkProps;
    back?: NonCircularLinkProps;
    disableBackButton?: boolean;
    disableBreadcrumbs?: boolean;
    disableButtonBar?: boolean;
    disableButtonBarButtons?: string[];
    disableLayout?: boolean;
    disableTitleLink?: boolean;
    disableLayoutMaxWidth?: boolean;
    preventLayoutOverflow?: boolean;
    title?: string | null;
  }
}

// Render the app
const rootElement = document.getElementById('root')!;

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);

  root.render(
    <StrictMode>
      <Suspense fallback="Loading...">
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </Suspense>
    </StrictMode>,
  );
}
