import { useTheme } from '@mui/material';
import { Toaster as HotToaster } from 'react-hot-toast';

export const Toaster = () => {
  const theme = useTheme();

  return (
    <HotToaster
      toastOptions={{
        position: 'bottom-center',

        success: {
          iconTheme: {
            primary: theme.palette.success.main,
            secondary: theme.palette.success.contrastText,
          },
        },

        error: {
          iconTheme: {
            primary: theme.palette.error.main,
            secondary: theme.palette.error.contrastText,
          },
        },
      }}
    />
  );
};
