import { useMemo } from 'react';

import { createTheme, PaletteOptions, responsiveFontSizes } from '@mui/material';
import { useParams } from '@tanstack/react-router';
import deepmerge from 'deepmerge';

import { AppInfo } from 'api/types/AppInfo';

import { DefaultColors } from 'assets/styles/defaultColors';

import { useQueryAppData } from 'hooks/useQuery/useQueryAppData';

const t = createTheme();

type ThemeOptions = Parameters<typeof createTheme>[0];

interface UseThemeOptions {
  id?: string;
  themeOptions?: ThemeOptions;
}

export const useTheme = ({ id = undefined, themeOptions = {} }: UseThemeOptions = {}) => {
  const params = useParams({ strict: false });

  const { data: app } = useQueryAppData(id || params?.id || process.env.APP_ID);

  const palette: PaletteOptions = useMemo(() => {
    return Object.keys(DefaultColors).reduce((acc, k) => {
      const token = k as keyof typeof DefaultColors;
      const colorName = token.toLowerCase().replace(/_/g, '-') as keyof AppInfo['colors'];

      const main = (app?.colors[colorName] || DefaultColors[token]) as string;

      const contrastTextKey = `${colorName}ContrastText` as keyof AppInfo['colors'];

      return {
        ...acc,
        [colorName]: t?.palette?.augmentColor({
          name: token,
          color: {
            main,
            contrastText: app?.colors[contrastTextKey] || undefined,
          },
        }),
      };
    }, {} as PaletteOptions);
  }, [app]);

  const theme = useMemo(() => {
    const hookOptions: ThemeOptions = { cssVariables: { cssVarPrefix: 'tpm' }, palette, typography: {} };
    const mergedOptions = deepmerge(hookOptions, themeOptions);

    return createTheme(mergedOptions);
  }, [themeOptions, palette]);

  return responsiveFontSizes(theme);
};
