import { unifiedAppParser } from 'api/parsers/unifiedAppParser';
import { fetch } from 'api/typedFetch';

export const getData = async () => {
  const response = await fetch('GET_APPS');
  const data = await response.json();

  if (data) {
    return data
      .map((app) => {
        return unifiedAppParser(app);
      })
      .sort((a, b) => {
        return Number(b.id) - Number(a.id);
      });
  }

  throw new Error('Failed to fetch source apps');
};
