import { ForgotPasswordResponse } from './responses/FORGOT_PASSWORD';
import { GetChapterContentResponse } from './responses/GET_CHAPTER_CONTENT';
import { GetContactUsResponse } from './responses/GET_CONTACT_US';
import { LoginResponse } from './responses/LOGIN';
import { UpdateUserResponse } from './responses/UPDATE_USER';
import { RawAppAsset } from './types/AppAsset/RawAppAsset';
import { RawAppInfo } from './types/AppInfo/RawAppInfo';
import { RawChapterInfo } from './types/ChapterInfo/RawChapterInfo';
import { RawCollectionItem } from './types/CollectionItem/RawCollectionItem';
import { RawFavourite } from './types/Favourite/RawFavourite';
import { RawLibrary } from './types/Library/RawLibrary';
import { RawProgressData } from './types/ProgressData/RawProgressData';
import { RawRegisteredApp } from './types/RegisteredApp/RawRegisteredApp';
import { RawUserInfo } from './types/UserInfo/RawUserInfo';

// TODO: Move all of this to a separate package, ideally all type generation should happen outside of the app, preferably on the server if possible (7350757293)

export enum Endpoints {
  FORGOT_PASSWORD = 'https://login.travpromobile.com/api/forgot_password',
  GET_APPS = 'https://cms.travpromobile.com/api/theSource/getApps',
  GET_APP_INFO = `https://cms.travpromobile.com/api/app/app-info/`,
  GET_ASSETS = 'https://cms.travpromobile.com/api/app/sales-companion',
  GET_CHAPTER_CONTENT = 'https://cms.travpromobile.com/api/app/chapter-content',
  GET_CHAPTER_INFO = 'https://cms.travpromobile.com/api/app/chapter-info',
  GET_COLLECTION = 'https://cms.travpromobile.com/api/app/collection',
  GET_CONTACT_US = 'https://cms.travpromobile.com/api/contact-us',
  GET_DIPLOMA = 'https://cms.travpromobile.com/api/app/v2/diploma',
  GET_IMAGE_LIBRARY = 'https://cms.travpromobile.com/api/app/v2/carousel-photo',
  GET_PROGRESS = 'https://progressv2.travpromobile.com/progress/apps',
  GET_REGISTERED_APPS = 'https://crm.travpromobile.com/api/registered-apps',
  GET_SHOWCASES = 'https://cms.travpromobile.com/api/showcases',
  GET_TRADE_SHOWS = 'https://cms.travpromobile.com/api/trade-shows',
  GET_USER = 'https://accounts.travpromobile.com/api/v2/get_user',
  GET_VIDEO_LIBRARY = 'https://cms.travpromobile.com/api/app/carousel-video-inline',
  LOGIN = 'https://login.travpromobile.com/oauth/token',
  PASSWORDLESS = 'https://login.travpromobile.com/api/login/passwordless',
  REGISTER_USER_PROGRESS = 'https://progress.travpromobile.com/users/',
  SET_CHAPTER_COMPLETED = 'https://crm.travpromobile.com/api/chapter/',
  SET_PROGRESS = 'https://progress.travpromobile.com/progress/',
  UPDATE_USER = 'https://accounts.travpromobile.com/api/v2/user/',
  GET_FAVOURITES = 'https://progressv2.travpromobile.com/favorites',
  SET_FAVOURITES = 'https://progress.travpromobile.com/favorites',
}

export type EndpointKey = keyof typeof Endpoints;

interface SetChapterCompletedResponse {
  result: string;
  response: string;
}

interface RegisterUserProgressResponse {
  /* Only set on success */
  data?: {
    id: number;
    email: string;
  };
  /* Only set on error */
  errors?: {
    email: string[];
  };
}

interface DefaultResponseShapes extends Record<EndpointKey, unknown> {
  UPDATE_USER: UpdateUserResponse;
  FORGOT_PASSWORD: ForgotPasswordResponse;
  GET_APPS: RawAppInfo[];
  GET_CHAPTER_CONTENT: GetChapterContentResponse;
  GET_CONTACT_US: GetContactUsResponse;
  SET_CHAPTER_COMPLETED: SetChapterCompletedResponse;
  LOGIN: LoginResponse;

  // Working on it

  // Has generator
  GET_PROGRESS: (RawAppInfo & { chapters: RawProgressData[] })[];
  REGISTER_USER_PROGRESS: RegisterUserProgressResponse;
  GET_COLLECTION: { items: Record<string, RawCollectionItem[]> };
  GET_APP_INFO: { app: [RawAppInfo] };
  GET_ASSETS: RawAppAsset[];
  GET_CHAPTER_INFO: { app: RawChapterInfo[] };
  GET_IMAGE_LIBRARY: { items: Record<string, RawLibrary>[] };
  GET_REGISTERED_APPS: RawRegisteredApp[];
  GET_SHOWCASES: RawAppInfo[];
  GET_TRADE_SHOWS: { app: [RawAppInfo[]] };
  GET_USER: { response: RawUserInfo };
  GET_VIDEO_LIBRARY: { items: Record<string, RawLibrary>[] };
  GET_FAVOURITES: RawFavourite;
  SET_FAVOURITES: { data: { app_id: number; type: string; unid: string; user_id: number } };
}

type FilteredResponseShapes<T> = {
  [K in keyof T]: K extends EndpointKey ? T[K] : never;
};

export type ResponseShapes = FilteredResponseShapes<DefaultResponseShapes>;
