import { keys, StorageKeys, StorageValues } from 'constants/storageKeys';

type InferredType<T extends StorageKeys> = T extends keyof StorageValues ? StorageValues[T] : unknown;

const appName = process.env.packageName;

const getNamedKey = (key: StorageKeys) => {
  return [`[${appName}]`, keys[key]].join(' ');
};

export const getLocalStorage = <T extends StorageKeys>(key: T, initialValue?: InferredType<T>) => {
  const value = localStorage.getItem(getNamedKey(key));

  if (value === null) {
    return initialValue;
  }

  return JSON.parse(value) as InferredType<T>;
};

export const setLocalStorage = <T extends StorageKeys>(key: T, value: InferredType<T>) => {
  localStorage.setItem(getNamedKey(key), JSON.stringify(value));
};

export const clearLocalStorageKey = <T extends StorageKeys>(key?: T) => {
  if (key === undefined) {
    localStorage.clear();
    return;
  }

  localStorage.removeItem(getNamedKey(key));
};

const typedLocalStorage = {
  get: getLocalStorage,
  set: setLocalStorage,
  clear: clearLocalStorageKey,
};

export { typedLocalStorage as localStorage };
