import { useQuery } from '@tanstack/react-query';

import { getData } from 'api/requests/GET_APP_INFO';

import { getQueryKey } from 'helpers/getQueryKey';

export const useQueryAppData = (id: string) => {
  return useQuery({
    queryKey: getQueryKey('APP', { [id]: true }),
    queryFn: async () => {
      return getData(id);
    },
  });
};
