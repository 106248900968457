import { parseRegisteredApps } from 'api/parsers/parseRegisteredApps';
import { fetch } from 'api/typedFetch';

export const getData = async (email: string) => {
  const response = await fetch('GET_REGISTERED_APPS', {
    method: 'GET',
    params: new URLSearchParams({ email }),
  });

  if (response.ok) {
    const data = await response.json();

    return data.map(parseRegisteredApps);
  }

  throw new Error('Registered apps request failed');
};
