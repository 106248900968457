import { useLocalStorage } from '@uidotdev/usehooks';

import { keys, StorageKeys, StorageValues } from 'constants/storageKeys';

type InferredType<T extends StorageKeys> = T extends keyof StorageValues ? StorageValues[T] : unknown;

const useTypeSafeLocalStorage = <T extends StorageKeys>(key: T, initialValue?: InferredType<T>) => {
  const appName = process.env.packageName;

  if (appName === undefined) {
    throw new Error('App name is not defined');
  }

  const namedKey = [`[${appName}]`, keys[key]].join(' ');

  return useLocalStorage<InferredType<T> | undefined>(namedKey, initialValue);
};

export { useTypeSafeLocalStorage as useLocalStorage };
