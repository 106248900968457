import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      throwOnError: process.env.NODE_ENV === 'development',
      retry: Number(process.env.NODE_ENV === 'production'),
      staleTime: 1000 * 60 * 5,
    },
  },
});
