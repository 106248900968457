import { parseProgressData } from 'api/parsers/parseProgressData';
import { fetch } from 'api/typedFetch';

import { setData as registerUserProgress } from './REGISTER_USER_PROGRESS';

export const getData = async ({ resource, email }: { resource: string | string[]; email?: string }) => {
  const idsToFetch = [resource].flat();

  if (idsToFetch.length === 0) {
    return [];
  }

  if (!email) {
    throw new Error('Email is required');
  }

  const params = new URLSearchParams({ email });

  idsToFetch.forEach((id) => {
    params.append('appIds[]', id);
  });

  const response = await fetch('GET_PROGRESS', { params });

  // Verifies that the userprogress is correctly registered in the backend, or does so if not already done.
  let data;
  if (response.ok === true) {
    data = await response.json();
  } else {
    const registrationResponse = await registerUserProgress();

    if (registrationResponse.ok !== true && registrationResponse.status !== 422) {
      throw new Error('Cannot verify user progress');
    }

    const secondProgressResponse = await fetch('GET_PROGRESS', { params });

    data = await secondProgressResponse.json();
  }

  const parsedData = data.map((progressData) => {
    /* @ts-expect-error - I don't understand how this function works */
    return parseProgressData(progressData, progressData.app_id.toString());
  });

  return parsedData;
};
