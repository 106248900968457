import { useMemo } from 'react';

import { keepPreviousData, QueryClient, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { getData } from 'api/requests/GET_APP_PROGRESS';
import { ProgressData } from 'api/types/ProgressData';

import { getQueryKey } from 'helpers/getQueryKey';
import { getLocalStorage } from 'helpers/localStorage';

import { useLocalStorage } from 'hooks/useHooks/useLocalStorage';

import { preloadRegisteredApps, useQueryRegisteredApps } from './useQueryRegisteredApps';
import { useQuerySourceApps } from './useQuerySourceApps';

const getQueryFn = (idsToFetch: string[], email?: string) => {
  return async () => {
    return getData({ resource: idsToFetch, email });
  };
};

export const preloadProgressData = async (queryClient: QueryClient, ids?: string[]) => {
  const user = getLocalStorage('USER');
  const registeredApps = await preloadRegisteredApps(queryClient);

  const registeredAppIds = registeredApps.map((app) => {
    return app.appId;
  });

  const idsToFetch: string[] = [];

  if (ids !== undefined) {
    ids.forEach((id) => {
      if (registeredAppIds.includes(id)) {
        idsToFetch.push(id);
      }
    });
  }

  if (idsToFetch.length === 0) {
    return [];
  }

  return queryClient.fetchQuery({
    queryKey: getQueryKey('PROGRESS', idsToFetch.sort()),
    queryFn: getQueryFn(idsToFetch, user?.email),
  });
};

const sortByProgress = (a: ProgressData, b: ProgressData) => {
  return b.chapterCompletion - a.chapterCompletion;
};

export const generateQueryKey = (ids?: string | string[]) => {
  return getQueryKey('PROGRESS', [ids].flat().filter(Boolean).sort() as string[]);
};

export const useQueryProgressData = (rawIds?: string | string[], options?: Partial<UseQueryOptions>) => {
  const [user] = useLocalStorage('USER');

  const ids = [rawIds].flat().filter(Boolean) as string[];
  const idsPassed = rawIds !== undefined && ids.length > 0;

  const { data: apps } = useQuerySourceApps(undefined, { enabled: idsPassed });
  const { data: registeredApps } = useQueryRegisteredApps(undefined, {
    enabled: idsPassed && user?.email !== undefined,
  });

  const idsToFetch = useMemo(() => {
    if (registeredApps === undefined) {
      return [];
    }

    const newIdsToFetch: string[] = [];

    const registeredIds = (registeredApps || []).map((app) => {
      return app.appId;
    });
    if (idsPassed) {
      ids.forEach((id) => {
        if (registeredIds.includes(id)) {
          newIdsToFetch.push(id);
        }
      });
    }

    return newIdsToFetch;
  }, [ids, idsPassed, registeredApps]);

  const enabled = user?.email !== undefined && options?.enabled !== false;

  return useQuery({
    enabled,
    queryKey: getQueryKey('PROGRESS', idsToFetch.sort()),
    queryFn: getQueryFn(idsToFetch, user?.email),
    placeholderData: keepPreviousData,
    select: (data: ProgressData[]) => {
      const outputs = {
        raw: data,
        completed: [],
        byLevel: {
          level2: [],
          level3: [],
        },
        byAppId: {},
      } as {
        raw: ProgressData[];
        completed: ProgressData[];
        byLevel: {
          level2: ProgressData[];
          level3: ProgressData[];
        };
        byAppId: Record<string, ProgressData>;
      };

      (apps || []).forEach((app) => {
        const progress = data.find((progress) => {
          return progress.id === app.id;
        });

        if (progress === undefined) {
          return;
        }

        outputs.byAppId[app.id] = progress;

        if (progress.chapterCompletion === 100) {
          outputs.completed.push(progress);
        }

        if (app.level === 2) {
          outputs.byLevel.level2.push(progress);
        }

        if (app.level === 3) {
          outputs.byLevel.level3.push(progress);
        }
      });

      outputs.byAppId = Object.values(outputs.byAppId)
        .sort(sortByProgress)
        .reduce((acc, progress) => {
          return {
            ...acc,
            [progress.id]: progress,
          };
        }, {});

      outputs.completed = outputs.completed.sort(sortByProgress);
      outputs.byLevel.level2 = outputs.byLevel.level2.sort(sortByProgress);
      outputs.byLevel.level3 = outputs.byLevel.level3.sort(sortByProgress);

      return outputs;
    },
  });
};
