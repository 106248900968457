import React, { createContext, useContext, useEffect, useState } from 'react';

import { useLocation } from '@tanstack/react-router';
import ReactGA from 'react-ga4';

interface AnalyticsContextValue {
  /** Initialisation state of Google Analytics */
  initialised: boolean;
  /** Send a pageview */
  // pageview: (page: string, title: string) => void;
  /** Send a custom event */
  send: typeof ReactGA.event;
}

const defaultContextValue: AnalyticsContextValue = {
  initialised: false,
  // pageview: (page, title) => {
  //   const payload = { hitType: 'pageview', page, title };
  //   ReactGA.send(payload);
  // },
  send: ReactGA.event,
};

const AnalyticsContext = createContext(defaultContextValue);

interface AnalyticsContextProviderProps {
  children: React.ReactNode;
}

// Context provider for Google Analytics, which allows for the same instance to be used across the application and encapsulating any future custom events.
export const AnalyticsContextProvider = ({ children }: AnalyticsContextProviderProps) => {
  const [value, setValue] = useState(defaultContextValue);
  const location = useLocation();

  // Initialise Google Analytics.
  // NOTE: includes a check for NODE_ENV === 'production' to prevent initialisation in dev mode while still having a !== 'production' check later.
  // This second check is to allow for testing in dev if you (temporarily) comment the first node_env check.
  useEffect(() => {
    if (
      process.env.NODE_ENV === 'production' &&
      value.initialised === false &&
      process.env.GA_MEASUREMENT_ID !== undefined
    ) {
      ReactGA.initialize(process.env.GA_MEASUREMENT_ID, {
        testMode: process.env.NODE_ENV !== 'production',
        gaOptions: { debug_mode: process.env.NODE_ENV !== 'production' },
      });

      setValue({
        ...defaultContextValue,
        initialised: ReactGA.isInitialized,
      });
    }
  }, [value.initialised]);

  // Sends a pageview event on route change (removed from the context value as this will ensure appwide-wide coverage)
  useEffect(() => {
    if (value.initialised === true) {
      const payload = { hitType: 'pageview', page: location.pathname, title: location.pathname };
      ReactGA.send(payload);
    }
  }, [location.pathname, value]);

  return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
};

export const useAnalytics = () => {
  return useContext(AnalyticsContext);
};
