import { StorageKeys } from 'constants/storageKeys';

export type Resource<T extends StorageKeys> = Record<T | string, string | number | boolean> | string | number;

export const getQueryKey = <K extends StorageKeys>(
  key: K,
  ...resource: (Resource<K> | Resource<K>[])[]
): (StorageKeys | Resource<K>)[] => {
  if (resource !== undefined) {
    return [key, [resource]].flat(2) as (StorageKeys | Resource<K>)[];
  }

  return [key];
};
