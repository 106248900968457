import camelcaseKeys from 'camelcase-keys';

import { RegisteredApp } from '../types/RegisteredApp';
import { RawRegisteredApp } from '../types/RegisteredApp/RawRegisteredApp';

interface ParsedRegisteredApp {
  [key: string]: RawRegisteredApp[keyof RawRegisteredApp] | null | boolean | Date | string[];
  custom: string[];
}

export const parseRegisteredApps = (rawData: RawRegisteredApp) => {
  const data = { ...rawData };
  const camelApp = camelcaseKeys(data, { deep: true });

  const output: ParsedRegisteredApp = { ...camelApp, custom: [] };

  Object.entries(camelApp).forEach(([k, v]) => {
    const key = k as keyof typeof camelApp;
    const value = v as (typeof camelApp)[keyof typeof camelApp];

    if (typeof key === 'string' && key.startsWith('custom')) {
      if (typeof value === 'string') {
        output.custom = [...output.custom, value];
      }
      delete output[key];
    }

    if (value === '') {
      output[key] = null;
    }

    if (typeof value === 'number' && [0, 1].includes(value)) {
      output[key] = Boolean(value);
    }
  });

  output.id = camelApp.id?.toString() ?? null;

  [
    'createdAt',
    'updatedAt',
    'marketingTravelBrandsOptInDate',
    'salesCompanionRegistered',
    'trainingStarted',
    'trainingGraduated',
  ].forEach((k) => {
    const key = k as keyof typeof camelApp;

    const dateTime = new Date(camelApp[key] || 0).getTime();

    if (camelApp[key] === null || Number.isNaN(dateTime)) {
      output[key] = null;
      return;
    }

    output[key] = dateTime;
  });

  return output as unknown as RegisteredApp;
};
