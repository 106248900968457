interface Meta {
  [key: string]: string;
  title: string;
}

export const addMeta = (meta: Meta) => {
  return () => {
    return [meta];
  };
};
