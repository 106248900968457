import { unifiedAppParser } from 'api/parsers/unifiedAppParser';
import { fetch } from 'api/typedFetch';

export const getAppData = async (id: string) => {
  const response = await fetch('GET_APP_INFO', {
    method: 'GET',
    params: new URLSearchParams({ app_id: id, format: 'json' }),
  });

  if (response.ok) {
    const data = await response.json();

    return data;
  }

  throw new Error(`Failed to fetch data for app ${id}`);
};

export const getData = async (id: string | number) => {
  const { app } = (await getAppData(id.toString())) || {};

  const [data] = app;

  const parsedData = unifiedAppParser({ ...data, id: Number(id) });

  return parsedData;
};
