import { keepPreviousData, QueryClient, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { getData } from 'api/requests/GET_REGISTERED_APPS';
import { RegisteredApp } from 'api/types/RegisteredApp';

import { getQueryKey } from 'helpers/getQueryKey';
import { localStorage } from 'helpers/localStorage';

import { useLocalStorage } from 'hooks/useHooks/useLocalStorage';

export const queryKey = getQueryKey('REGISTERED_APPS');

const queryFn = () => {
  const user = localStorage.get('USER');

  if (!user?.email) {
    throw new Error('Email is required');
  }

  return getData(user?.email);
};

const getSelect = (id?: string) => {
  return (data: RegisteredApp[]) => {
    if (id === undefined) {
      return data;
    }

    return data.filter((app) => {
      return app.appId === id;
    });
  };
};

export const preloadRegisteredApps = async (queryClient: QueryClient, id?: string) => {
  return getSelect(id)(await queryClient.ensureQueryData({ queryKey, queryFn }));
};

export const useQueryRegisteredApps = (id?: string, options?: Partial<UseQueryOptions<RegisteredApp[]>>) => {
  const [user] = useLocalStorage('USER');

  const enabled = user?.email !== undefined && options?.enabled;

  return useQuery({
    queryKey,
    queryFn,
    placeholderData: keepPreviousData,
    select: (rawData) => {
      const data = getSelect(id)(rawData);

      if (options?.select === undefined) {
        return data;
      }

      return options.select(data);
    },
    enabled,
  });
};
