export enum RawType {
  FASTFACTS = 'fastfacts',
  TOOLBOX = 'toolbox',
}

export enum RealType {
  PROMOTE = 'promote',
  SELL = 'sell',
}

export const getRealType = (type: string): RawType.FASTFACTS | RawType.TOOLBOX | string => {
  switch (type) {
    case 'promote':
      return RawType.FASTFACTS;

    case 'sell':
      return RawType.TOOLBOX;

    default:
      return type;
  }
};

export const getRawType = (type: 'fastfacts' | 'toolbox') => {
  switch (type) {
    case 'fastfacts':
      return RealType.PROMOTE;

    default:
      return RealType.SELL;
  }
};
