import { StrictMode as ReactStrictMode } from 'react';

export interface StrictModeProps {
  disabled?: boolean;
  children: React.ReactNode;
}

export const StrictMode = ({ disabled = false, children }: StrictModeProps) => {
  if (disabled) {
    return children;
  }

  return <ReactStrictMode>{children}</ReactStrictMode>;
};
