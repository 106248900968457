import { useTranslate } from 'hooks/useTranslate';

import c from './_contactView.module.scss';

export const ErrorComponent = () => {
  const { t } = useTranslate('contactView');

  return (
    <div className={c.wrapper}>
      <h1>{t('error.failedToFetch')}</h1>
    </div>
  );
};
