import camelcaseKeys from 'camelcase-keys';
import { MicroLearningApp } from 'typescript/enums/MicroLearningApp.d';

import { AppInfo } from 'api/types/AppInfo';
import { RawAppInfo } from 'api/types/AppInfo/RawAppInfo';

import { DefaultColors } from 'assets/styles/defaultColors';

import { convertImgixUrl } from 'helpers/convertImgixUrl';
import { isImage } from 'helpers/isImage';

type AppColors = {
  company: string;
  companyContrastText?: string;

  primary: string;
  primaryContrastText?: string;

  secondary: string;
  accent: string;
  darkText: string;
  lightText: string;
  dark: string;
  light: string;
  success: string;
  warning: string;
  error: string;
  diploma: string;
  diplomaCheckmark: string;
};

interface AppData {
  [key: string]: Record<string, unknown> | boolean | boolean | null | number | string | string[] | Date | unknown[];
  colors: AppColors;
  directoryUrl: AppInfo['directoryUrl'];
}

type AppParser = (data: RawAppInfo, id?: string | null) => AppInfo;

const numericKeys = ['backgroundType', 'statusId'];

export const unifiedAppParser: AppParser = (rawData, id = null) => {
  const camelApp = camelcaseKeys({ ...rawData }, { deep: true });

  const app = camelApp as typeof camelApp & {
    companyColor: string;
    companyContrastText: string;

    primary: string;
    primaryContrastText: string;

    accentColor: string;
    darkTextColor: string;
    lightTextColor: string;
    successColor: string;
    warningColor: string;
    errorColor: string;
  };

  const colors = {
    company: app?.companyColor || DefaultColors.COMPANY, // TODO: Missing from API? (7350350997)

    primary: app?.color || DefaultColors.PRIMARY,
    primaryContrastText: app?.primaryContrastText || null,

    secondary: app.secondaryColor || DefaultColors.SECONDARY,
    accent: app?.accentColor || DefaultColors.ACCENT, // TODO: Missing from API? (7350350997)
    darkText: app?.darkTextColor || DefaultColors.DARK_TEXT, // TODO: Missing from API? (7350350997)
    lightText: app?.lightTextColor || DefaultColors.LIGHT_TEXT, // TODO: Missing from API? (7350350997)
    dark: app?.colorDark || DefaultColors.DARK,
    light: app?.colorLight || DefaultColors.LIGHT,
    success: app?.successColor || DefaultColors.SUCCESS,
    warning: app?.warningColor || DefaultColors.WARNING, // TODO: Missing from API? (7350350997)
    error: app?.errorColor || DefaultColors.ERROR,
    diploma: app?.diplomaColor || DefaultColors.COMPANY,
    diplomaCheckmark: app?.diplomaCheckmarkColor || DefaultColors.SUCCESS,
  } as AppColors;

  const output = Object.keys(app)
    .sort()
    .reduce((obj, key) => {
      const value = app[key as keyof typeof app];

      if (Array.isArray(value)) {
        return {
          ...obj,
          [key]: value.filter((entry) => {
            if (typeof entry === 'string' && entry.length === 0) {
              return false;
            }

            return true;
          }),
        };
      }

      if (typeof value === 'string' && value === '') {
        return { ...obj, [key]: null };
      }

      if ((typeof value === 'string' || typeof value === 'number') && [1, 0, '1', '0 '].includes(value)) {
        if (numericKeys.includes(key)) {
          return { ...obj, [key]: Number(value) };
        }

        return { ...obj, [key]: Boolean(Number(value)) };
      }

      return { ...obj, [key]: value };
    }, {} as AppData);

  // Delete stuff
  delete output.views;
  delete output.appHasCategories;

  output.colors = colors;

  output.keywords = (app.keywords || '').split(',').filter((keyword) => {
    return keyword.length > 0;
  });

  output.id = (id || app?.id)?.toString();

  if (output.id === '760') {
    output.colors.primaryContrastText = '#FFFFFF'; // TEMP: Fix for white text on white background
  }

  if (Object.keys(MicroLearningApp).includes(output.id)) {
    const { value } = MicroLearningApp[output.id as keyof typeof MicroLearningApp].value;
    output.microLearnings = value;
  } else {
    output.microLearnings = [];
  }

  output.level = { ii: 2, iii: 3 }[app.level] || null;

  output.categories = camelApp?.appHasCategories?.map((c) => {
    const cat = c as unknown as {
      id: number;
      appId: number;
      categoryId: number;
      createdAt: string;
      updatedAt: string;
      category: {
        id: number;
        name: string;
        subType: string;
        createdAt: string;
        updatedAt: string;
      };
    };

    return {
      categoryId: cat.categoryId,
      name: cat.category.name,
      createdAt: new Date(cat.createdAt),
      id: cat.id,
      type: cat.category.subType,
      updatedAt: new Date(cat.updatedAt),
    };
  });

  output.startDate = new Date(app.startDate === '0000-00-00 00:00:00' ? 0 : app.startDate);
  output.endDate = new Date(app.endDate === '0000-00-00 00:00:00' ? 0 : app.endDate);

  // Convert all URLs that look like images to Imgix URLs
  (Object.keys(app) as (keyof typeof app)[]).forEach((key) => {
    const value = app[key];

    if (typeof value !== 'string') return;
    if (!isImage(value)) return;

    output[key] = convertImgixUrl(value);
  });

  const benefits = output.programBenefits as string | string[] | undefined;

  if (typeof benefits === 'string' && benefits.includes('\n')) {
    output.programBenefits = benefits.split('\n');
  }

  if (typeof output.directoryUrl === 'string') {
    const params = new URLSearchParams({ header: 'false', source: 'true' });
    output.directoryUrl = [output.directoryUrl.split('?')[0], params.toString()].join('?');
  }

  return output as unknown as AppInfo;
};
