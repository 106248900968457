type Value = { name: string; value: string[] };

export class MicroLearningApp {
  static readonly '620' = new MicroLearningApp('620', { name: 'California', value: ['California 10 minute Modules'] }); // Commented out since this is the calafornia app id.

  static readonly '787' = new MicroLearningApp('787', { name: 'Canada', value: ['Canada 10 minute Modules'] });

  private constructor(
    private readonly key: string,
    public readonly value: Value,
  ) {
    this.key = key;
    this.value = value;
  }

  toString() {
    return this.key;
  }
}
